/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-else-return */
/* eslint-disable object-shorthand */
/* eslint-disable brace-style */
/* eslint-disable react/jsx-filename-extension */
import { envConfig } from '@hc/config';
import { getDateFormat } from '@hc/dayjs';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useDoctorProfile = create((set, get) => ({
  profile: {
    name: '',
    qualification: '',
    speciality: [],
    serving_from: new Date(),
    address: '',
    language: [],
    doctor_defined_language: [],
    doctor_defined_speciality: [],
    about: '',
    profile_pic: '',
    mobile_no: '',
    country_code: '',
    email_id: '',
    working_at: [],
    googleAuthToken: '',
    sync_mail: '',
    sync_mail_update: '',
    register_no: '',
    state_medical_council_name: '',
    verification: '',
    year_of_registration: '',
    validation: {
      register_no: '',
      state_medical_council_name: '',
      verification: '',
      year_of_registration: '',
    },
    error: {
      name: '',
      qualification: '',
      speciality: '',
      working_at: '',
    },
  },
  profileCopy: {
    name: '',
    qualification: '',
    speciality: [],
    serving_from: '',
    address: '',
    language: [],
    about: '',
    // profile_pic: '',
    working_at: [],
    register_no: '',
    state_medical_council_name: '',
    year_of_registration: '',
  },
  progressValue: 0,
  loading: null,
  error: null,
  loadingPhoto: null,

  setPersistedProfileData: (payload) => {
    set((state) => {
      const { profile } = state;
      return {
        profile: {
          ...profile,
          ...payload,
        },
      };
    });
  },

  setAuthValue: () => {
    const { profile } = get();
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    set({
      profile: {
        ...profile,
        register_no: tokenData.register_no,
        state_medical_council_name: tokenData.state_medical_council_name,
        year_of_registration: tokenData.year_of_registration,
      },
    });
  },

  upsertDoctorProfile: async (user_id) => {
    try {
      set({ loading: true });
      const { profile, progressValueUpdate, getProgressValue } = get();
      // if (option?.value === option?.labelShow) {
      //   const array = profile?.doctor_defined_speciality;
      //   array.push(option?.value);
      //   set({
      //     profile: {
      //       ...profile,
      //       doctor_defined_speciality: array,
      //     },
      //   });
      // }
      const data = {
        user_id,
        doctor_details: {
          workingAt: profile?.working_at ?? [],
          name: profile?.name ?? '',
          qualification: profile?.qualification ?? '',
          profilePic: profile?.profile_pic ?? '',
          // doctor_defined_speciality: profile?.doctor_defined_speciality ?? [],
          doctor_defined_speciality:
            profile?.speciality.length > 0
              ? profile?.speciality
                  .filter((val) => val?.value === val?.labelShow)
                  .map((obj) => obj?.value)
              : [],
          doctor_defined_language:
            profile?.language.length > 0
              ? profile?.language
                  .filter((val) => val?.value === val?.labelShow)
                  .map((obj) => obj?.value)
              : [],
          speciality:
            profile?.speciality.length > 0
              ? profile?.speciality
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => obj)
              : [],
          // speciality: profile?.speciality ?? [],
          serving_from: profile?.serving_from
            ? getDateFormat(profile?.serving_from, 'YYYY-MM-DD')
            : '',
          regNo: profile?.register_no ?? '',
          address: profile?.address ?? '',
          language:
            profile?.language.length > 0
              ? profile?.language
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => obj)
              : [],
          about: profile?.about ?? '',
          email: profile?.email_id ?? '',
          register_no: profile?.register_no,
          state_medical_council_name: profile?.state_medical_council_name,
          year_of_registration: profile?.year_of_registration,
        },
      };
      const response = await httpRequest(
        'post',
        `${envConfig.api_url_v1}/doctors/update/profile`,
        {
          ...data,
        },
        true,
      );
      if (response?.data?.statusCode === '200') {
        set({
          profileCopy: {
            name: profile?.name ?? '',
            qualification: profile?.qualification ?? '',
            speciality: profile?.speciality ?? [],
            serving_from: profile?.serving_from ?? '',
            address: profile?.address ?? '',
            language: profile?.language ?? '',
            about: profile?.about ?? '',
            doctor_defined_speciality: [],
            doctor_defined_language: [],
            // profile_pic: profile?.profile_pic ?? '',
            working_at: profile?.working_at ?? [],
            register_no: profile?.register_no ?? '',
            state_medical_council_name: profile.state_medical_council_name,
            year_of_registration: profile.year_of_registration,
          },
        });
        set({
          profile: {
            ...profile,
            doctor_defined_speciality: [],
            doctor_defined_language: [],
          },
        });

        progressValueUpdate();
        const percentageResponse = getProgressValue(profile);
        if (percentageResponse !== 100) {
          toast.success('Profile Partially Updated!!!');
        } else {
          toast.success(
            response?.data?.message ?? 'Profile Updated Successfully!!!',
          );
        }
        return set({ loading: false });
      }
      toast.error('Something went wrong please try again!');
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.message ??
          err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // Upsert Profile Basic Info
  upsertProfileBasicInfo: async (userId) => {
    try {
      const { profile } = get();
      set({ loading: true });
      const data = {
        user_id: userId ?? '',
        name: profile?.name ?? '',
        working_at: profile?.working_at ?? '',
        email_id: profile?.email_id ?? '',
        qualification: profile?.qualification ?? '',
        doctor_defined_speciality:
          profile?.speciality.length > 0
            ? profile?.speciality
                .filter((val) => val?.value === val?.labelShow)
                .map((obj) => obj?.value)
            : [],
        doctor_defined_language:
          profile?.language.length > 0
            ? profile?.language
                .filter((val) => val?.value === val?.labelShow)
                .map((obj) => obj?.value)
            : [],
        speciality:
          profile?.speciality.length > 0
            ? profile?.speciality
                .filter((val) => val?.value !== val?.labelShow)
                .map((obj) => obj)
            : [],
        serving_from: profile?.serving_from
          ? getDateFormat(profile?.serving_from, 'YYYY-MM-DD')
          : '',
        address: profile?.address ?? '',
        language:
          profile?.language.length > 0
            ? profile?.language
                .filter((val) => val?.value !== val?.labelShow)
                .map((obj) => obj)
            : [],
        about: profile?.about ?? '',
        googleAuthCode: profile?.googleAuthToken ?? '',
        register_no: profile.register_no,
        state_medical_council_name: profile.state_medical_council_name,
        year_of_registration: profile.year_of_registration,
      };
      // Hitting the signin API
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/doctor/upsert-doctor-profile`,
        {
          ...data,
        },
        true,
      );
      if (response?.data?.status?.code === 200) {
        set({ loading: false });
        set({
          profile: {
            ...profile,
            doctor_defined_speciality: [],
            doctor_defined_language: [],
          },
        });
        return response?.data?.status?.code;
      }
      set({ loading: false });
      return response?.data?.status?.code;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  constructSpecialityData: (data) => {
    const arr = [];
    if (Array.isArray(data) && data?.length > 0) {
      data.map((val) => {
        const obj = {
          value: val?.speciality_id ?? '',
          label: val?.master_speciality?.speciality ?? '',
        };
        arr.push(obj);
      });
    }
    return arr;
  },
  constructLanguageData: (data) => {
    const arr = [];
    if (Array.isArray(data) && data?.length > 0) {
      data.map((val) => {
        const obj = {
          value: val?.language_id ?? '',
          label: val?.master_language?.language ?? '',
        };
        arr.push(obj);
      });
    }
    return arr;
  },

  getProgressValue: (profileData) => {
    let count = 0;
    let variables = [
      'name',
      'qualification',
      'speciality',
      'address',
      'language',
      'about',
      'profile_pic',
      'working_at',
      'register_no',
    ];
    // iterate over the user object
    for (const key in profileData) {
      if (variables.includes(key)) {
        if (profileData[key]?.length > 0) {
          count++;
        }
      } else if (key === 'serving_from') {
        if (getDateFormat(profileData[key], 'YYYY-MM-DD')?.length > 0) {
          count++;
        }
      }
    }
    const percentage = (count / 10) * 100;
    return percentage ? Math.round(percentage) : 0;
  },

  progressValueUpdate: () => {
    const { getProgressValue, profile } = get();
    const progressValueData = getProgressValue(profile);
    set({
      progressValue: progressValueData ?? 0,
    });
  },
  compareOldState: () => {
    const { profile, profileCopy } = get();
    const profileData = {
      name: profile?.name ?? '',
      qualification: profile?.qualification ?? '',
      speciality: profile?.speciality ?? [],
      serving_from: profile?.serving_from ?? '',
      address: profile?.address ?? '',
      language: profile?.language ?? [],
      about: profile?.about ?? '',
      // profile_pic: profile?.profile_pic ?? '',
      working_at: profile?.working_at ?? [],
      register_no: profile?.register_no ?? '',
    };
    return JSON.stringify(profileData) === JSON.stringify(profileCopy);
  },

  getDoctorProfileData: async (doctor__id) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data } = await queryClient.fetchQuery([
        '/doctor/get-doctor-profile',
        'post',
        {
          doctor_id: doctor__id,
        },
      ]);
      if (status?.code === 200) {
        const { profile, constructSpecialityData, constructLanguageData } =
          get();
        set({
          profile: {
            ...profile,
            error: {
              name: '',
              qualification: '',
              speciality: '',
              working_at: '',
            },
            name: data?.first_name ?? data?.name ?? '',
            qualification: data?.education ?? '',
            speciality:
              Array.isArray(data?.doctor_specialities) &&
              data?.doctor_specialities?.length > 0
                ? constructSpecialityData(data?.doctor_specialities)
                : [],
            serving_from:
              data?.serving_from && data?.serving_from?.length > 0
                ? new Date(data?.serving_from)
                : '',
            address: data?.address ?? '',
            doctor_defined_language: [],
            doctor_defined_speciality: [],
            language:
              Array.isArray(data?.user_languages) &&
              data?.user_languages?.length > 0
                ? constructLanguageData(data?.user_languages)
                : [],
            about: data?.about ?? '',
            profile_pic: data?.profile_pic ?? '',
            mobile_no: data?.mobile_no ?? '',
            country_code: data?.mobile_no_country_code ?? '',
            email_id: data?.email_id ?? '',
            working_at: data?.working_at ?? [],
            sync_mail: data?.sync_mail ?? '',
            sync_mail_update: data?.user_calendars?.[0]?.updatedAt ?? '',
            register_no: data?.register_no ?? '',
            state_medical_council_name: data?.state_medical_council_name ?? '',
            year_of_registration: data?.year_of_registration ?? '',
          },
          profileCopy: {
            name: data?.first_name ?? data?.name ?? '',
            qualification: data?.education ?? '',
            speciality:
              Array.isArray(data?.doctor_specialities) &&
              data?.doctor_specialities?.length > 0
                ? constructSpecialityData(data?.doctor_specialities)
                : [],
            serving_from:
              data?.serving_from && data?.serving_from?.length > 0
                ? new Date(data?.serving_from)
                : '',
            address: data?.address ?? '',
            language:
              Array.isArray(data?.user_languages) &&
              data?.user_languages?.length > 0
                ? constructLanguageData(data?.user_languages)
                : [],
            about: data?.about ?? '',
            // profile_pic: data?.profile_pic ?? '',
            working_at: data?.working_at ?? [],
            register_no: data?.register_no ?? '',
            state_medical_council_name: data?.state_medical_council_name ?? '',
            year_of_registration: data?.year_of_registration ?? '',
          },
        });

        const { progressValueUpdate } = get();
        progressValueUpdate();
        set({ loading: false });
      }
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      if (error.message === 'Request failed with status code 401') {
        // toast('Redirecting to the login page, please continue', { icon: '⚠️' });
        localStorage.clear();
        return routeTo(useRouting, doctorRoutes.signin);
      }
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  addSpeciality: (e, option) => {
    const { profile, isIamValided } = get();
    const { speciality } = profile;
    // if (option?.value === option?.labelShow) {
    //   const array = profile?.doctor_defined_speciality;
    //   array.push(option?.value);
    //   set({
    //     profile: {
    //       ...profile,
    //       doctor_defined_speciality: array,
    //     },
    //   });
    // }
    let specialityDataCopy = JSON.parse(JSON.stringify(speciality));
    if (specialityDataCopy.length > 0) {
      const specialityDataFilter = specialityDataCopy.filter(
        (v) => v?.value === option?.value,
      );
      if (!specialityDataFilter?.length > 0) {
        specialityDataCopy.push(option);
      } else {
        const specialityDataFilter = specialityDataCopy.filter(
          (v) => v?.value !== option?.value,
        );
        specialityDataCopy = specialityDataFilter;
      }
    } else {
      specialityDataCopy.push(option);
    }
    set({
      profile: {
        ...profile,
        speciality: specialityDataCopy,
      },
    });
    return isIamValided('speciality');
  },

  addLanguage: (e, option) => {
    const { profile } = get();
    const { language } = profile;
    // if (option?.value === option?.labelShow) {
    //   const array = profile?.doctor_defined_language;
    //   array.push(option?.value);
    //   set({
    //     profile: {
    //       ...profile,
    //       doctor_defined_language: array,
    //     },
    //   });
    // }
    let languageDataCopy = JSON.parse(JSON.stringify(language));
    if (languageDataCopy.length > 0) {
      const languageDataFilter = languageDataCopy.filter(
        (v) => v?.value === option?.value,
      );
      if (!languageDataFilter?.length > 0) {
        languageDataCopy.push(option);
      } else {
        const specialityDataFilter = languageDataCopy.filter(
          (v) => v?.value !== option?.value,
        );
        languageDataCopy = specialityDataFilter;
      }
    } else {
      languageDataCopy.push(option);
    }
    set({
      profile: {
        ...profile,
        language: languageDataCopy,
      },
    });
  },
  // ADD WORKING AT
  addWorkingAt: (text) => {
    const { profile, isIamValided } = get();
    const { working_at } = profile;
    const working_atCopy = JSON.parse(JSON.stringify(working_at));
    working_atCopy.push(text);
    set({
      profile: {
        ...profile,
        working_at: working_atCopy,
      },
    });
    return isIamValided('working_at');
  },

  // CLEAR PAGE DATA
  clearBasicInfo: (key) => {
    const { profile, isIamValided } = get();
    set({
      profile: { ...profile, [key]: [] },
    });
    if (type === 'speciality' || type === 'working_at') {
      return isIamValided(key);
    }
  },

  // DELETE DATA
  deleteData: (type, i) => {
    const { profile, isIamValided } = get();
    const copyData = JSON.parse(JSON.stringify(profile?.[type]));
    copyData.splice(i, 1);
    set({
      profile: {
        ...profile,
        [type]: copyData,
      },
    });
    if (type === 'speciality' || type === 'working_at') {
      return isIamValided(type);
    }
  },

  handleProfileDataChange: (key, value) => {
    const { profile, isIamValided } = get();
    const profileCopy = JSON.parse(JSON.stringify(profile));
    set({
      profile: {
        ...profileCopy,
        [key]: value,
      },
    });
    return isIamValided(key);
  },

  // Delete PROFILE PICTURE
  deleteProfileImage: async (id, type) => {
    const { profile } = get();
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/userprofiles/delete-profile-picture`,
        {
          user_id: id,
        },
        true,
      );
      if (response.data?.status?.code === 200) {
        set({
          profile: {
            ...profile,
            profile_pic: '',
          },
          loading: false,
        });
        if (type === 'doctorProfile') {
          const { progressValueUpdate } = get();
          progressValueUpdate();
        }
        toast.success(response?.data?.status?.message ?? '');
        return response;
      }
    } catch (error) {
      set({ loading: false });
      // eslint-disable-next-line no-undef
      log('error', err);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  profilePictureUpsert: async (event, user_id, type) => {
    try {
      set({ loadingPhoto: true });
      event.preventDefault();

      const formdata = new FormData();
      const files = event.target.files?.[0];

      formdata.append('file', files);
      formdata.append('id', user_id);
      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(localStorageKeys?.authToken) ?? ''
          }`,
        },
      };
      const uploads = await fetch(
        `${envConfig.api_url}/userprofiles/upsert-profile-picture`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => {
          console.log('error', error);
        });
      set({ loadingPhoto: false });
      if (uploads?.status?.code === 200) {
        const { updateProfilePicture, progressValueUpdate } = get();
        await updateProfilePicture(uploads?.data?.profile_pic);
        if (type === 'doctorProfile') {
          progressValueUpdate();
        }

        toast.success(
          uploads?.status?.message ??
            'User Profile Picture Updated Successfully',
        );
      } else {
        toast.error('Something went wrong please try again!');
      }
      // return uploads;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // UPDATE PROFILE PICTURE
  updateProfilePicture: (profile_pic_url) => {
    const { profile } = get();
    set({
      profile: {
        ...profile,
        profile_pic: profile_pic_url,
      },
    });
  },

  isIamValided: (key) => {
    const { profile } = get();
    const profileCopy = JSON.parse(JSON.stringify(profile));
    const error = profileCopy?.error;

    const fieldsToValidate = {
      name: {
        errorMessage: 'Please enter your name.',
        value: profile?.name,
      },
      qualification: {
        errorMessage: 'Qualification is required',
        value: profile?.qualification,
      },
      working_at: {
        errorMessage: 'Work location is required',
        value: profile?.working_at,
      },
      speciality: {
        errorMessage: 'Speciality is required',
        value: profile?.speciality,
      },
    };

    let isValid = true;
    // Loop over the fields to validate
    Object.entries(fieldsToValidate).forEach(
      ([field, { errorMessage, value }]) => {
        // If a key is provided, only validate that field
        if (key && key !== field) {
          return;
        }
        // If the current field is invalid, set the isValid flag to false and set the error message
        if (!value || value.length === 0) {
          isValid = false;
          error[field] = errorMessage;
        } else {
          error[field] = '';
        }
      },
    );

    set({
      profile: {
        ...profile,
        error: {
          ...error,
        },
      },
    });

    return isValid;
  },
  isDocterValidToSave: () => {
    const { profile } = get();
    const profileCopy = JSON.parse(JSON.stringify(profile));
    const validation = profileCopy?.validation;
    let isValid = true;
    if (!profile.register_no) {
      isValid = false;
      validation.register_no = 'Please enter the register no';
    } else {
      validation.register_no = '';
    }

    if (!profile.state_medical_council_name) {
      isValid = false;
      validation.state_medical_council_name =
        'Please enter state medical council name';
    } else {
      validation.state_medical_council_name = '';
    }

    if (!profile.year_of_registration) {
      isValid = false;
      validation.year_of_registration = 'Please enter year of registration';
    } else {
      validation.year_of_registration = '';
    }

    if (!profile.verification) {
      isValid = profile.verification;
      toast.error('Please check the information and Acknowledge');
    }

    set({
      profile: {
        ...profile,
        validation: {
          ...validation,
        },
      },
    });
    return isValid;
  },

  // Refresh auth token
  refreshAuthToken: async () => {
    try {
      set({ loading: true });
      // Hitting the signup API
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/doctor-auth-refresh`,
        {},
        true,
      );
      // If the user is new
      if (response?.data?.status?.code === 200) {
        set({ loading: false });
        localStorage.setItem(
          localStorageKeys.authToken,
          response?.data?.data?.auth_token,
        );
      }
      set({ loading: false });
      return response?.data?.status?.code;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  updateGoogleCalendar: async (googleAuthCode) => {
    try {
      const { profile } = get();
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['googleCalendarSync'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/google-calendar/sync`,
            { googleAuthCode: googleAuthCode },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        set({
          profile: {
            ...profile,
            sync_mail: data?.sync_email ?? '',
            sync_mail_update: data?.last_sync_update ?? '',
          },
        });
        set({ loading: false });
        return status;
      }
      set({ loading: false });
      return status;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.status?.message ??
          err?.data?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
}));
